:root {
    --ak-accent: #fd4b2d;

    --ak-dark-foreground: #fafafa;
    --ak-dark-foreground-darker: #bebebe;
    --ak-dark-foreground-link: #5a5cb9;
    --ak-dark-background: #18191a;
    --ak-dark-background-darker: #000000;
    --ak-dark-background-light: #1c1e21;
    --ak-dark-background-light-ish: #212427;
    --ak-dark-background-lighter: #2b2e33;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--ak-accent);
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

html {
    --pf-c-nav__link--PaddingTop: 0.5rem;
    --pf-c-nav__link--PaddingRight: 0.5rem;
    --pf-c-nav__link--PaddingBottom: 0.5rem;
    --pf-c-nav__link--PaddingLeft: 0.5rem;
}

html > form > input {
    position: absolute;
    top: -2000px;
    left: -2000px;
}

.pf-icon {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    vertical-align: middle;
}

.pf-c-page__header {
    z-index: 0;
    background-color: var(--ak-dark-background-light);
    box-shadow: var(--pf-global--BoxShadow--lg-bottom);
}

/*****************************
* Login adjustments
*****************************/
/* Ensure card is displayed on small screens */
.pf-c-login__main {
    display: block;
    position: relative;
    width: 100%;
}
.ak-login-container {
    height: calc(100vh - var(--pf-global--spacer--lg) - var(--pf-global--spacer--lg));
    width: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.pf-c-login__header {
    flex-grow: 1;
}
.pf-c-login__footer {
    flex-grow: 2;
    display: flex;
    justify-content: end;
    flex-direction: column;
}
.pf-c-login__footer ul.pf-c-list.pf-m-inline {
    justify-content: center;
    padding: 2rem 0;
}
/*****************************
* End Login adjustments
*****************************/

.pf-c-content h1 {
    display: flex;
    align-items: flex-start;
}
.pf-c-content h1 i {
    font-style: normal;
}
.pf-c-content h1 :first-child {
    margin-right: var(--pf-global--spacer--sm);
}

/* ensure background on non-flow pages match */
.pf-c-background-image::before {
    background-image: var(--ak-flow-background);
    background-position: center;
}

.pf-m-success {
    color: var(--pf-global--success-color--100) !important;
}
.pf-m-warning {
    color: var(--pf-global--warning-color--100);
}
.pf-m-danger {
    color: var(--pf-global--danger-color--100);
}

.form-help-text {
    color: var(--pf-global--Color--100);
}

.pf-c-description-list__description .pf-c-button {
    margin-right: 6px;
    margin-bottom: 6px;
}

.pf-c-data-list__item {
    background-color: transparent;
}
